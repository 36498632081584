import {Model, Attr, BelongsTo, HasMany, HasOne} from 'spraypaint'

import ApplicationRecord from '@/models/ApplicationRecord'
import { GiftCard, Card, CardDistribution, CustomerInvoice, MultiChoiceCard, SentGift } from '@/models/index'
import { useUserStore } from '@/stores/user'

@Model()
export default class CustomerOrder extends ApplicationRecord {
  static jsonapiType = 'customer_orders'
  static endpoint = '/orders'

  @Attr() createdAt!: string
  @Attr() number!: number
  @Attr() amount!: number
  @Attr() discountedAmount!: number
  @Attr() multiChoiceCardsAmount!: number
  @Attr() status!: string
  @Attr() paymentStatus!: string
  @Attr() deliveryStatus!: string
  @Attr() customerEmail!: string
  @Attr() creditCardId!: string
  @Attr() creditCardPan!: string
  @Attr() creditCardBrand!: string
  @Attr() fromCaretaker!: boolean
  @Attr() paymentFormToken!: string
  @Attr() giftRecipientFirstname!: string
  @Attr() giftRecipientLastname!: string
  @Attr() giftRecipientEmail!: string
  @Attr() giftRecipientMessage!: string
  @Attr() inDeliveryDistribution!: any
  @Attr() externalPaymentUrl!: string

  @BelongsTo() giftCard!: GiftCard
  @BelongsTo() customerInvoice!: CustomerInvoice

  @HasMany() cards!: Card[]
  @HasMany() cardDistributions!: CardDistribution[]
  @HasMany() multiChoiceCards!: MultiChoiceCard[]

  @HasOne() sentGift!: SentGift

  private userStore = useUserStore()

  public cardDistributionsSum(): number {
    if (this.cardDistributions.length === 0) return 0
    return this.cardDistributions.reduce((acc: number, current: CardDistribution): number => {
      return acc + (Number(current.quantity) * Number(current.amount))
    }, 0)
  }

  public discountOrder(newAmount: number | null = null): number | string {
    if (!this.giftCard?.discount?.discount) return 0
    if (newAmount !== null && newAmount <= 0) return 0

    const discount = (newAmount || this.amount) * (this.giftCard.discount.discount / 100)

    if (Number.isInteger(discount)) return discount
    return Number.parseFloat(String(Math.round((discount + Number.EPSILON) * 100) / 100))
  }

  public discountedAmountOrder(newAmount: number | null = null): number {
    if (!this.giftCard?.discount) return 0
    if (newAmount !== null && newAmount <= 0) return 0

    return (newAmount || this.amount) - Number(this.discountOrder(newAmount))
  }

  public usableMultiChoiceCardsAmount(): number {
    return this.multiChoiceCards.reduce((total, multiChoiceCard) => total + multiChoiceCard.remainingAmount, 0)
  }

  public baseAmountForDiscount(): number {
    return Math.max(this.amount - this.usableMultiChoiceCardsAmount(), 0)
  }

  static getRemainingAmountToDelivered(giftCardId: string): any {
    return this
      .where({ giftCardId: giftCardId })
      .per(0)
      .stats({ amount: 'not_delivered' })
  }
}
