import VueRouter, { RouteConfig } from 'vue-router'
import { useUserStore } from '@/stores/user'

export const routes: RouteConfig[] = [
  {
    path: '/',
    redirect: '/home',
    meta: {
      fullscreen: false
    }
  },
  {
    path: '/home',
    component: () => import('@/views/home/index.vue'),
    name: 'Home',
    meta: {
      fullscreen: false
    },
    beforeEnter: (to, from, next) => {
      const userStore = useUserStore()
      if (userStore.isBemoveCustomer || userStore.isAvantagesIEGCustomer) next('/search')
      else next()
    }
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/sign-in/index.vue'),
    meta: {
      fullscreen: true
    }
  },
  {
    path: '/verification',
    name: 'Verification',
    component: () => import('@/views/verification/index.vue'),
    meta: {
      fullscreen: true,
      hideNavigation: true
    }
  },
  {
    path: '/sign-up',
    name: 'SignUp',
    component: () => import('@/views/sign-up/index.vue'),
    meta: {
      fullscreen: true,
      hideNavigation: true
    }
  },
  {
    path: '/confirmed-email',
    name: 'ConfirmedEmail',
    component: () => import('@/views/confirmed-email/index.vue'),
    meta: {
      fullscreen: true,
      hideNavigation: true
    }
  },
  {
    path: '/unconfirmed-email',
    name: 'UnconfirmedEmail',
    component: () => import('@/views/unconfirmed-email/index.vue'),
    meta: {
      fullscreen: true,
      hideNavigation: true
    }
  },
  {
    path: '/profile',
    name: 'Profile',
    component: () => import('@/views/profile/index.vue'),
    meta: {
      fullscreen: false
    }
  },
  {
    path: '/notifications',
    name: 'Notifications',
    component: () => import('@/views/notifications-list/index.vue'),
    meta: {
      fullscreen: false
    }
  },
  {
    path: '/orders',
    name: 'Orders',
    component: () => import('@/views/orders-list/index.vue'),
    meta: {
      fullscreen: false
    }
  },
  {
    path: '/wallet/',
    component: () => import('@/views/wallet/index.vue'),
    props: true,
    meta: {
      fullscreen: false
    },
    children: [
      {
        path: '',
        redirect: '/wallet/ebons',
      },
      {
        path: 'ebons',
        component: () => import('@/views/wallet/components/PurchasedGiftCardsList.vue')
      },
      {
        path: 'multi-choice-cards',
        component: () => import('@/views/wallet/components/MultiChoiceCardsList.vue')
      }
    ]
  },
  {
    path: '/search',
    name: 'Search',
    component: () => import('@/views/search-giftcard/index.vue'),
    meta: {
      fullscreen: false
    }
  },
  {
    path: '/gift-cards/:id',
    name: 'GiftCardSheet',
    component: () => import('@/views/gift-card-sheet/index.vue'),
    props: true,
    meta: {
      fullscreen: false
    }
  },
  {
    path: '/wallet/:id',
    name: 'WalletCards',
    props: true,
    component: () => import('@/components/WalletGiftCards/GiftCardsWalletDetails.vue'),
    meta: {
      fullscreen: false
    }
  },
  {
    path: '/opt-in-refused',
    name: 'OptInRefused',
    component: () => import('@/views/opt-in-refused/index.vue'),
    meta: {
      fullscreen: true
    }
  },
  {
    path: '/cgv',
    name: 'CGV',
    component: () => import('@/components/TermsOfUse.vue'),
    meta: {
      fullscreen: false
    }
  },
  {
    path: '/token-expired',
    name: 'TokenExpired',
    component: () => import('@/views/token-expired/index.vue'),
    meta: {
      fullscreen: true
    }
  },
  {
    path: '/locked',
    name: 'Locked',
    component: () => import('@/views/locked/index.vue'),
    meta: {
      fullscreen: true
    }
  },
  {
    path: '/sav',
    name: 'SAV',
    component: () => import('@/views/sav-kommunicate/index.vue'),
    meta: {
      fullscreen: false
    }
  },
  {
    path: '*',
    redirect: '/home'
  }
]

const createRouter = () =>
  new VueRouter({
    mode: 'history',
    scrollBehavior: (to, from, savedPosition) => {
      if (savedPosition) return savedPosition
      else return { x: 0, y: 0 }
    },
    base: process.env.BASE_URL,
    routes: routes
  })

const router = createRouter()

export function resetRouter(): void {
  const newRouter: any = createRouter();
  (router as any).matcher = (newRouter as any).matcher
}

export default router
